<template>
    <div class="border border-gray-100 bg-white px-2 py-1 rounded inline-flex items-center gap-2 h-[30px]">
        <span class="font-medium text-gray-700 text-sm">Orientare</span>
        <span class="text-gray-400 text-xs">•</span>
        <span class="text-gray-500 text-xs">Politică</span>
        <span :class="`px-1.5 py-0.5 rounded-lg font-medium text-xs ${politicalLeaning.leaningColor}`">
      {{ politicalLeaning.leaningText }}
    </span>
    </div>
</template>

<script>
export default {
    props: {
        politicalLeaning: {
            type: Object,
            required: true,
        },
    },
};
</script>
